<template>
    <svg class="svg-icon" :style="svgStyle" aria-hidden="true" v-on="$listeners">
        <use :xlink:href="`#icon-${iconName}`" rel="external nofollow" />
    </svg>
</template>
  
<script>
export default {
    name: "SvgIcon",
    props: {
        iconName: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: "16",
        },
        color: {
            type: String,
            default: "#000",
        },
    },
    computed: {
        className() {
            return `svg-icon-${this.icon} svg-icon-${this.size}`;
        },
        svgStyle() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
                fill: this.color,
            };
        },
    },
};
</script>
  
<style scoped>
.svg-icon {
    width: 18px;
    height: 18px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>
  