import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'animate.css';
import './styles/theme.css'
import router from './router/index';
import store from './store/index';
import '@/icons'
import SvgIcon from '@/components/SvgIcon'
import 'vant/lib/dialog/style';
import uniWebview from '@dcloudio/uni-webview-js'


Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.component('svg-icon', SvgIcon)
window.uniWebview = uniWebview;
window.eventBus = new Vue();

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')



export default app