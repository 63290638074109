import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        token: '',
        theme: 'dark',
        heads_config: {},
        userInfo: {},
        chatList: [],
        currentChat: {},
        flag: 0,//因页面监听不到chatList的变化，所以用flag来监听
    },
    mutations: {
        heads_config(state, val) {
            state.heads_config = val;
        },
        setToken(state, val) {
            state.token = val;
            localStorage.setItem('token', state.token);
        },
        setUserInfo(state, val) {
            state.userInfo = val;
            localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
        },
        setTheme(state, val) {
            state.theme = val;
            document.documentElement.dataset.theme = state.theme;
            localStorage.setItem('theme', state.theme);
        },
        themeChange(state, val) {
            state.theme = state.theme === 'dark' ? 'light' : 'dark';
            document.documentElement.dataset.theme = state.theme;
            localStorage.setItem('theme', state.theme);
        },
        setChatList(state, val) {
            state.chatList = val;
        },
        addChatList(state, val) {
            let index = state.chatList.findIndex((item) => { return item.id === val.id; })
            if (index === -1) {
                state.chatList.push(val);
            } else {
                state.chatList[index] = val;
            }
            localStorage.setItem('chatList', JSON.stringify(state.chatList));
        },
        /*  updateOneChatList(state, val) {
             let index = state.chatList.findIndex((item) => { return item.id === val.id; })
             if (index) {
                 state.chatList[index] = val;
             }
             localStorage.setItem('chatList', JSON.stringify(state.chatList));
         }, */
        removeChatList(state, index) {
            state.chatList.splice(index, 1);
            localStorage.setItem('chatList', JSON.stringify(state.chatList));
        },
        setCurrentChat(state, val) {
            state.currentChat = val;
            localStorage.setItem('currentChat', JSON.stringify(state.currentChat));
        },
        setFlag(state, val) {
            state.flag = val;
        }
    },
    actions: {
        // 定义触发异步操作的方法
    },
    getters: {
        // 定义获取状态的方法
    },
});

export default store;
