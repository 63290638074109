// router.js

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 创建VueRouter实例，并定义路由配置
const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/home/home.vue'),
            meta: {
                title: '主页',
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login.vue'),
            meta: {
                title: '登录',
            },
        }, {
            path: '/register',
            name: 'register',
            component: () => import('@/views/register.vue'),
            meta: {
                title: '注册',
            },
        }, {
            path: '/forgotPassword',
            name: 'forgotPassword',
            component: () => import('@/views/forgotPassword.vue'),
            meta: {
                title: '忘记密码',
            },
        }, {
            path: '/home',
            name: 'home',
            component: () => import('@/views/home/home.vue'),
            meta: {
                title: '主页',
            },
        }

    ]
});

export default router;
