import axios from 'axios'
import { BASEURL } from '@/config.js'
import { getUCID } from '@gby/uniqueid'
import $store from '@/store/index.js'
import $router from '@/router/index'
import { refreshToken } from '@/api/index'
import { Message, Notification } from 'element-ui'
const dayjs = require('dayjs')
const service = axios.create({
  baseURL: BASEURL,
  timeout: 60000
})


const requestConf = (config) => {
  let token = $store.state.token
  if (!token) {
    token = localStorage.getItem("token");
    if (token) {
      $store.commit('setToken', token)
    }
  }

  let heads_config = $store.state.heads_config
  if (JSON.stringify(heads_config) == '{}') {
    heads_config = JSON.parse(localStorage.getItem("heads_config"))
    if (heads_config) {
      $store.commit('heads_config', heads_config)
    }
  }

  // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzbWFydC1haS1hcGkuaXRlc3QuNWZvb2RiYXNlLmNvbSIsImp0aSI6InJ6UDI5ZXNMIiwiaWF0IjoxNjkxOTc5Mjg0LCJzdWIiOiI0NjcxNzUwMDc4ODIxODY3NTIiLCJleHAiOjE2OTIwMDA4ODQsInBob25lX21vYmlsZSI6IjE3NjAxMzYyMDE4IiwiZW1haWwiOiJ1bnNldF8xNzYwMTM2MjAxOF9idFdXdGRAdW5rbm93bi1lbWFpbC5jb20iLCJ1c2VybmFtZSI6IuW-ruS_oeeUqOaItzE3NjAxMzYyMDE4In0.r_iCiINSkg-_7Ux6YafkhLpS5PSA672VZwvHI6wbjzM'
  // const pos_node_id = ''
  // const heads_config = {
  //   application_channel_code: "wechatapp",
  //   application_code: "mall-wechatapp",
  //   client_id: "16721940871678266626",
  //   client_ver: "1.0.0",
  //   company_id: "80011001",
  //   platform_code: "8001",
  //   pos_device_id: "16721940871678266626",
  //   request_queue_id: 1691978370,
  //   app_appid: 'wx672a2945e6ce05b7'
  // }


  config.headers['Content-Type'] = "application/json"
  config.headers['Authorization'] = `Bearer ${token}`
  // config.headers['Authorization'] = `Bearer 2322`


  if (config.method == 'post') {
    // console.log('post打印请求原始请求参数', config.data)
    config.data = {
      headers: {
        ...heads_config,
        client_id: getUCID(),
        request_queue_id: dayjs().unix(),
        is_no_auth: config.noAuth || false
      },
      body: config.data === 'undefined' || config.data === null ? [] : config.data,
    }
  }
  return config
}
service.interceptors.request.use(
  requestConf,
  error => {
    return Promise.reject(error)
  }
)


service.interceptors.response.use(
  response => {
    const {
      data,
      status,
      config
    } = response
    if (status === 200 && data.code == 200) {
      return data.data
    } else if (status === 200 && data.code === 401) {
      $store.commit('setToken', '')
      $store.commit('setUserInfo', {})
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      //跳转到登录页面
      $router.push('/login')
    } else if (status === 200 && data.code === 402) {
      tryRefreshToken(config)
    } else {
      Message.error(data.message)
      return Promise.reject(data.data)
    }
  }, error => {
    return Promise.reject(error)
  }
)


let refreshToking = false
let requests = []

const tryRefreshToken = async (config) => {
  if (refreshToking) {
    return new Promise((resolve, reject) => {
      requests.push({
        resolve,
        reject
      })
    })
  }

  refreshToking = true
  const { data } = await refreshToken()
  refreshToking = false
  if (data.code === 200) {
    $store.commit('setToken', data.data.token)
    localStorage.setItem('token', data.data.token)
    requests.forEach(cb => cb.resolve())
    requests = []
    return service(config)
  } else {
    $store.commit('setToken', '')
    $store.commit('setUserInfo', {})
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    //跳转到登录页面
    $router.push('/login')
  }
}



export default service