<template>
  <div id="app">
    <router-view v-if="ready"></router-view>
  </div>
</template>

<script>
import { getAppConfigApi } from "@/api/index.js";
export default {
  name: "App",
  components: {
  },
  data() {
    return {
      ready: false
    };
  },
  created() {
    const data = this.$store.state
    if (JSON.stringify(data.heads_config) === '{}' || !data.heads_config) {
      const heads_config = localStorage.getItem('heads_config')
      if (heads_config) {
        this.ready = true
        this.$store.commit('heads_config', JSON.parse(heads_config))
      } else {
        // this.$router.push('/login')
        this.getAppConfig();
      }

    } else {
      this.getAppConfig();
    }
    if (JSON.stringify(data.userInfo) === '{}' || !data.userInfo) {
      const userInfo = localStorage.getItem('userInfo')
      if (userInfo) {
        this.$store.commit('setUserInfo', JSON.parse(userInfo))
      } else {
        // this.$router.push('/login')
      }
    }

    if (!data.token) {
      const token = localStorage.getItem('token')
      if (token) {
        this.$store.commit('setToken', token)
      } else {
        // this.$router.push('/login')
      }
    }

    const chatList = localStorage.getItem('chatList')
    if (chatList) {
      this.$store.commit('setChatList', JSON.parse(chatList))
    }

    const currentChat = localStorage.getItem('currentChat')
    if (currentChat) {
      this.$store.commit('setCurrentChat', JSON.parse(currentChat))
    }

  },
  methods: {
    getAppConfig() {
      getAppConfigApi().then(res => {
        this.ready = true
        this.$store.commit('heads_config', res.heads_config)
        localStorage.setItem('heads_config', JSON.stringify(res.heads_config))
      });
    },
  },
};
</script>

<style scoped lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 14px;
  overflow: hidden;
}
</style>
