var BASEURL = 'https://api.test.ai-meta.cn/openapi/'
var IM = 'https://im-server.ai.suan80.com'
var IMWS = 'wss://im-server.ai.suan80.com/wss' // chatgpt_im; 'ws://118.178.58.184:5200'; 对应http://im.pro.fudebeisi.com
if (process.env.NODE_ENV === 'development') {
    BASEURL = 'https://api.test.ai-meta.cn/openapi/'
    IM = 'https://im-server.ai.suan80.com'
    IMWS = 'wss://im-server.ai.suan80.com/wss'

} else {
    BASEURL = 'https://api.test.ai-meta.cn/openapi/'
    IM = 'https://im-server.ai.suan80.com'
    IMWS = 'wss://im-server.ai.suan80.com/wss'
}

var config = {
    BASEURL,
    IM,
    IMWS,
}
module.exports = config
