import request from '@/utils/request'

export function unifyRequest(data, name = '', noAuth = false) {
    return request({
        url: `v1/dataset/call_kw?${name}`,
        method: 'post',
        data,
        noAuth: noAuth
    })
}

export function currentConfigsApi() {
    return request.post("v1/system/current_configs", {}, {
        noAuth: true
    });
}
export function getAppConfigApi() {
    return request({
        url: 'v1/system/appconfig',
        method: 'get',
        params: {
            company_code: 80011001,
            application_code: 800001
        }
    })
}


export function refreshToken() {
    return request({
        url: 'v1/sso/refresh_token',
        method: 'post',
        data: []
    })
}

export function getBaiduTokenApi() {
    return request({
        url: 'v1/api/baiduauth2',
        method: 'post',
        data: {
            client_id: 'mIzld7YLopBh8BkpodWQ8PN9',
            client_secret: 'xcnT1cLY828tI9ru44Cp6jsjfrmIT3jY'
        }
    })
}
